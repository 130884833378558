/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

// material-ui
import {
    Typography,
    Stack,
    Card,
    CardContent,
    Grid,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup,
    TextField,
    Autocomplete,
    Button
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

// project imports 'hooks/useScriptRef';
import SnackBarAlert from 'utils/snackBarAlert';
import { deleteApplications, getApplicationsNotPaginated, inviteTenant } from 'store/masterdata/products/applications/applicationActions';
import { getNotPaginatedUnits } from 'store/features/property/unitActions';
import { clearApplicationMessages } from 'store/masterdata/products/applications/applicationSlice';
import { gridSpacing } from '../../../store/constant';
import { formatDate } from 'hooks/hooks';
import CustomButton from 'ui-component/extended/CustomButton';
import { IconMailOpened } from '@tabler/icons';
import MuiGrid from '@mui/material/Grid';

//store imports

// ==============================|| Applications PAGE ||============================== /

const Popup = ({
    open,
    handleInputChange,
    handleClosePopup,
    isDisabled,
    first_name,
    last_name,
    email,
    setUnitId,
    unitId,
    units,
    handleInviteTenant
}) => (
    <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle>Invite Renter To Apply</DialogTitle>
        <DialogContent>
            <MuiGrid container spacing={3}>
                <MuiGrid item xs={6}>
                    <FormGroup>
                        <TextField
                            label="First Name"
                            required={true}
                            placeholder="Enter the name of the tenant"
                            margin="normal"
                            name="first_name"
                            value={first_name}
                            type="string"
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Email"
                            required={true}
                            margin="normal"
                            type="email"
                            value={email}
                            placeholder="Email"
                            name="email"
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                </MuiGrid>
                <MuiGrid item xs={6}>
                    <FormGroup>
                        <TextField
                            label="last name"
                            required={true}
                            margin="normal"
                            name="last_name"
                            placeholder="Last Name"
                            type="text"
                            value={last_name}
                            onChange={handleInputChange}
                        />
                        <Autocomplete
                            options={units}
                            getOptionLabel={(option) => option?.name || ''}
                            key={(option) => option?.id}
                            isOptionEqualToValue={(option, value) => option?.id === value}
                            onChange={(e, value) => {
                                setUnitId(value?.id);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Unit"
                                    placeholder="Start Typing...."
                                    name="unit"
                                    style={{ width: '100%' }}
                                />
                            )}
                        />
                    </FormGroup>
                </MuiGrid>
            </MuiGrid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClosePopup} color="secondary">
                Cancel
            </Button>
            <CustomButton disabled={!isDisabled} onClick={handleInviteTenant}>
                Send Invite
            </CustomButton>
        </DialogActions>
    </Dialog>
);

export default function ApplicationsPage({}) {
    const [alertOpen, setAlertOpen] = useState(false);
    const dispatch = useDispatch();
    const [unitId, setUnitId] = useState(null);
    let isDisabled = false;
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    //const { lang, configs } = useAppSelector((store) => store.appConfig);
    const { ApplicationMsg, ApplicationsSuccess, Applications } = useSelector((store) => store.applications);
    const { units } = useSelector((store) => store.units);
    const [occupiedUnits, setOccupiedUnits] = useState([]);

    isDisabled = first_name != '' && last_name != '' && email != '' && unitId != null;

    const navigate = useNavigate();
    const theme = useTheme();
    const { prop_id } = useParams();
    let propertyId = parseInt(prop_id);

    async function HandleRowClick(rowData) {
        navigate(`${rowData[0]}`);
    }
    const handleAlertClose = () => {
        dispatch(clearApplicationMessages());
        setAlertOpen(false);
    };
    useEffect(() => {
        dispatch(
            getApplicationsNotPaginated({
                property_id: propertyId
            })
        );
        dispatch(getNotPaginatedUnits({}));
    }, [dispatch, propertyId]);

    useEffect(() => {
        if (units.length > 0) {
            setOccupiedUnits(units?.filter((unit) => unit.status === 'VACANT' || unit.status === 'OPENING_UP_SOON'));
        }
    }, [units]);

    // handle delete Row(s)
    async function HandleRowDelete(row) {
        let rowIndex = row.data[0].dataIndex;
        let Application_id = data[rowIndex].id;
        dispatch(
            deleteApplications({
                id: Application_id
            })
        );
    }

    // Invite renters to apply
    const handleInputChange = useCallback(
        (e) => {
            const { name, value } = e.target;
            switch (name) {
                case 'first_name':
                    setFirstName(value);
                    break;
                case 'last_name':
                    setLastName(value);
                    break;
                case 'email':
                    setEmail(value);
                    break;
                case 'unit':
                    setUnitId(value);
                    break;
                default:
                    break;
            }
        },
        [setFirstName, setLastName, setEmail, setUnitId]
    );
    const inviteToApply = () => {
        setOpen(true);
    };

    const handleClosePopup = useCallback(() => {
        setOpen(false);
    }, []);

    const handleInviteTenant = useCallback(() => {
        dispatch(
            inviteTenant({
                first_name,
                last_name,
                email,
                unitId
            })
        );
        // Delay alert opening to ensure `setOpen(false)` completes
        setOpen(false);
        setTimeout(() => setAlertOpen(true), 0);
    }, [dispatch, first_name, last_name, email, unitId]);

    // Format data
    let data = [];
    let total_applications = 0;
    let currentMonthApplications = 0;
    let acceptedApplications = 0;
    let currentMonthAcceptedApplications = 0;

    if (Applications !== undefined && Applications.length > 0) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();

        data = Applications.map((application) => {
            const formattedApplication = {
                ...application,
                created: formatDate(application?.created_at),
                applying_to: application?.applying_to.name.concat(' ', application?.applying_to.units.location),
                applicant: application?.applicant?.full_names
            };

            // Check if the application is from the current month
            const applicationDate = new Date(application?.created_at);
            if (applicationDate.getMonth() === currentMonth) {
                currentMonthApplications++;

                // Check if the application status is ACCEPTED
                if (application.status === 'APPROVED') {
                    currentMonthAcceptedApplications++;
                }
            }

            if (application.status === 'APPROVED') {
                acceptedApplications++;
            }
            return formattedApplication;
        });

        total_applications = Applications.length;
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                sort: true,
                display: false
            }
        },
        {
            name: 'applicant',
            label: 'Applicant',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'created',
            label: 'Date Applied',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'applying_to',
            label: 'Unit',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return value == 'PENDING' ? (
                        <Stack direction="row">
                            <Chip label="Pending" style={{ backgroundColor: theme.palette.warning.dark, color: '#fff' }} />
                        </Stack>
                    ) : value == 'APPROVED' ? (
                        <Stack direction="row">
                            <Chip label="Accepted" style={{ backgroundColor: theme.palette.success.dark, color: '#fff' }} />
                        </Stack>
                    ) : (
                        <Stack direction="row">
                            <Chip label="Rejected" style={{ backgroundColor: theme.palette.error.dark, color: '#fff' }} />
                        </Stack>
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        onRowClick: (rowData) => HandleRowClick(rowData),
        onRowsDelete: (row) => HandleRowDelete(row),
        rowsPerPage: 10,
        responsive: 'standard',
        rowsPerPageOptions: [10, 20, 50, 100],
        jumpToPage: true,
        textLabels: {
            pagination: {
                next: '',
                previous: 'Previous',
                rowsPerPage: 'Total Items Per Page',
                displayRows: 'OF'
            }
        }
    };

    return (
        <>
            <>
                {ApplicationsSuccess ? (
                    <SnackBarAlert
                        alertOpen={alertOpen}
                        handleAlertClose={handleAlertClose}
                        height="30%"
                        setAlertOpen={setAlertOpen}
                        alertMessage={ApplicationMsg}
                        severity="success"
                    />
                ) : (
                    <SnackBarAlert
                        alertOpen={alertOpen}
                        handleAlertClose={handleAlertClose}
                        height="30%"
                        setAlertOpen={setAlertOpen}
                        alertMessage={ApplicationMsg}
                        severity="error"
                    />
                )}{' '}
            </>
            <Grid item sm={12} xs={12} md={12} lg={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item sm={12} xs={6} md={5} lg={4}>
                        <Card sx={{ width: 'auto', alignItems: 'center', justifyContent: 'center' }}>
                            <CardContent>
                                <Grid container>
                                    <Typography style={{ color: theme.palette.primary.dark }} variant="h3" gutterBottom>
                                        Total
                                    </Typography>
                                    <Grid container>
                                        <Grid item sm={6} xs={6} md={5} lg={5}>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                style={{
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    marginBottom: '0.2em'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: theme.palette.dark.dark,
                                                        marginRight: '5px',
                                                        marginLeft: '1px',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {total_applications}
                                                </span>
                                                <Typography style={{ color: theme.palette.text.secondary }} variant="subtitle1">
                                                    Leads
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} xs={6} md={6} lg={6}>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                style={{
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    marginBottom: '0.2em'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: theme.palette.dark.dark,
                                                        marginRight: '5px',
                                                        fontWeight: 'bold',
                                                        marginLeft: '1px'
                                                    }}
                                                >
                                                    {acceptedApplications}
                                                </span>
                                                <Typography style={{ color: theme.palette.text.secondary }} variant="subtitle1">
                                                    Matches
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* <Typography style={{ color: theme.palette.grey[500] }}>this Month</Typography> */}
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item sm={12} xs={6} md={5} lg={4}>
                        <Card sx={{ width: 'auto', alignItems: 'center', justifyContent: 'center' }}>
                            <CardContent>
                                <Grid container>
                                    <Typography style={{ color: theme.palette.primary.dark }} variant="h3" gutterBottom>
                                        This Month
                                    </Typography>
                                    <Grid container>
                                        <Grid item sm={6} xs={6} md={6} lg={6}>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                style={{
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    marginBottom: '0.2em'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: theme.palette.dark.dark,
                                                        marginRight: '5px',
                                                        fontWeight: 'bold',
                                                        marginLeft: '1px'
                                                    }}
                                                >
                                                    {currentMonthApplications}
                                                </span>
                                                <Typography style={{ color: theme.palette.text.secondary }} variant="subtitle1">
                                                    Leads
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} xs={6} md={6} lg={6}>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                style={{
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    marginBottom: '0.2em'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: theme.palette.dark.dark,
                                                        marginRight: '5px',
                                                        fontWeight: 'bold',
                                                        marginLeft: '1px'
                                                    }}
                                                >
                                                    {currentMonthAcceptedApplications}
                                                </span>
                                                <Typography style={{ color: theme.palette.text.secondary }} variant="subtitle1">
                                                    Matches
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={12} xs={6} md={2} lg={4}>
                        <CustomButton
                            sx={{ display: 'flex', textAlign: 'center', backgroundColor: theme.palette.secondary.dark }}
                            onClick={inviteToApply}
                        >
                            <IconMailOpened />
                            Invite To Apply
                        </CustomButton>{' '}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={gridSpacing} style={{ marginTop: '0.2em' }}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <MUIDataTable title={'Applications'} data={data} columns={columns} options={options} />
                </Grid>
            </Grid>
            <Popup
                open={open}
                handleInputChange={handleInputChange}
                handleClosePopup={handleClosePopup}
                isDisabled={isDisabled}
                first_name={first_name}
                last_name={last_name}
                email={email}
                units={occupiedUnits}
                unitId={unitId}
                inviteToApply={inviteToApply}
                setUnitId={setUnitId}
                handleInviteTenant={handleInviteTenant}
            />
        </>
    );
}
