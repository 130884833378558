import { createSlice } from '@reduxjs/toolkit';
import { getLoyaltyPoints, getLoyaltyPointsNotPaginated } from './loyaltyPointActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    LoyaltyPointsLoading: false,
    LoyaltyPointsSuccess: false,
    LoyaltyPointsMsg: '',
    Points: []
};

const LoyaltyPoint = createSlice({
    name: 'LoyaltyPoint',
    initialState,
    reducers: {
        clearLoyaltyPointsMessages: (state) => {
            state.error = false;
            state.LoyaltyPointsMsg = '';
            state.LoyaltyPointsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLoyaltyPoints.pending, (state) => {
            state.LoyaltyPointsLoading = true;
        });
        builder.addCase(getLoyaltyPoints.fulfilled, (state, action) => {
            state.LoyaltyPointsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Points = action.payload?.data[0];
            state.error = action.payload?.error;
            state.LoyaltyPointsMsg = action.payload?.msg;
            state.LoyaltyPointsSuccess = !action.payload?.error;
        });
        builder.addCase(getLoyaltyPointsNotPaginated.fulfilled, (state, action) => {
            state.LoyaltyPointsLoading = false;
            state.Points = action.payload?.data[0];
            state.error = action.payload?.error;
            state.LoyaltyPointsMsg = action.payload?.msg;
            state.LoyaltyPointsSuccess = !action.payload?.error;
        });
    }
});

export const { clearLoyaltyPointsMessages } = LoyaltyPoint.actions;

export default LoyaltyPoint.reducer;
