/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    Snackbar,
    Alert
} from '@mui/material';

import { styled } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import LoyaltyPointsList from './LoyaltyPointList';
import { getLoyaltyPointsNotPaginated } from 'store/masterdata/products/accounting/loyaltyPointActions';
import { selectAuthUser } from 'store/features/auth/auth';

// assets
import { IconCoins } from '@tabler/icons';

// ==============================|| NOTIFICATION ||============================== //

const LoyaltyPointSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userTokenPermissions } = useSelector((store) => store.auth);
    const [open, setOpen] = useState(false);
    const { userType } = useSelector(selectAuthUser);
    const { Points } = useSelector((store) => store.loyalty_points);
    const anchorRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        dispatch(getLoyaltyPointsNotPaginated({}));
    }, [dispatch]);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    // Calculate total points
    const totalPoints = Points?.total_points || 0;

    const handleRedeemPoints = () => {
        if (totalPoints >= 2500) {
            navigate('/tenant/loyalty-points');
        } else {
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                    Minimum 2500 points are required for redemption.
                </Alert>
            </Snackbar>
            {userType === 'TENANT' && (
                <Box
                    sx={{
                        ml: 2,
                        mr: 3,
                        [theme.breakpoints.down('md')]: {
                            mr: 2
                        }
                    }}
                >
                    <ButtonBase sx={{ borderRadius: '12px' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.pointsAvatar,
                                transition: 'all .2s ease-in-out',
                                background: '#fff',
                                padding: '10px',
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="inherit"
                        >
                            <IconCoins stroke={1.5} size="1.3rem" />
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    ml: 0.5,
                                    color: 'inherit',
                                    fontWeight: 600
                                }}
                            >
                                {totalPoints} Pts
                            </Typography>{' '}
                        </Avatar>
                    </ButtonBase>
                </Box>
            )}

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={8} sx={{ marginBottom: '10px' }}>
                                                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.dark }}>
                                                            Total Points: {totalPoints}
                                                        </Typography>
                                                        {totalPoints > 0 && (
                                                            <Typography
                                                                variant="subtitle1"
                                                                color="primary"
                                                                onClick={handleRedeemPoints}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'none',
                                                                    color: theme.palette.secondary.dark,
                                                                    transition: 'color 0.3s, text-decoration 0.3s, font-size 0.3s'
                                                                }}
                                                                onMouseEnter={(event) => {
                                                                    event.target.style.textDecoration = 'underline';
                                                                    event.target.style.color = theme.palette.secondary.dark;
                                                                    event.target.style.fontSize = '1.1em';
                                                                }}
                                                                onMouseLeave={(event) => {
                                                                    event.target.style.textDecoration = 'none';
                                                                    event.target.style.color = theme.palette.secondary.dark;
                                                                    event.target.style.fontSize = '1em';
                                                                }}
                                                            >
                                                                Redeem Points
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                    <Divider />
                                                    <Stack direction="row" spacing={2} sx={{ marginTop: '10px' }}>
                                                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                                                            Points and benefit activity
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <LoyaltyPointsList />
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                navigate(`/tenant/loyalty-points`);
                                                setOpen(false);
                                            }}
                                        >
                                            View all activity
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default LoyaltyPointSection;
