import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getLoyaltyPoints = createAsyncThunk('LoyaltyPoints/getPaginatedLoyaltyPoints', async ({ charge_category }) => {
    try {
        let url = `/rewards/getLoyaltyPoints?page=${page}`;

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getLoyaltyPointsNotPaginated = createAsyncThunk('LoyaltyPoints/getLoyaltyPoints', async () => {
    try {
        const { data } = await api.get(`/rewards/getLoyaltyPoints`);
        return data;
    } catch (error) {}
});
