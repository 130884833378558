/* eslint-disable no-unused-vars */
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getNotifications, markAsRead } from '../../../../store/features/communications/notificationsActions';
import { useNavigate } from 'react-router-dom';

// assets
import User1 from 'assets/images/users/user-round.svg';
import { createMarkup } from 'utils/createMarkUp';
import { gridSpacing } from 'store/constant';
import { getLoyaltyPointsNotPaginated } from 'store/masterdata/products/accounting/loyaltyPointActions';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const LoyaltyPointsList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Points } = useSelector((store) => store.loyalty_points);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.warning.dark
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.success.dark
    };

    useEffect(() => {
        dispatch(getLoyaltyPointsNotPaginated({}));
    }, [dispatch]);
    const pointsActivities = Points?.activities || [];

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {pointsActivities && pointsActivities.length > 0 ? (
                pointsActivities.slice(0, 2).map((activity) => (
                    <div key={activity.id}>
                        <ListItemWrapper>
                            <ListItem alignItems="center">
                                <ListItemText primary={activity.category} secondary={activity.description} />
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-start">
                                        <Grid item xs={12} sx={{ pb: 2 }}>
                                            <Chip label={`+${activity.points} Pts`} color="primary" size="small" />
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12}>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {new Date(activity.created_at).toLocaleDateString()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </div>
                ))
            ) : (
                <Typography variant="body2" textAlign="center">
                    No points activities found
                </Typography>
            )}
        </List>
    );
};

export default LoyaltyPointsList;
