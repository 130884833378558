import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getRentReports = createAsyncThunk('RentReports/getPaginatedgetRentReports', async ({ charge_category }) => {
    try {
        let url = `/credit-building/getRentReport?page=${page}`;

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getRentReportsNotPaginated = createAsyncThunk('RentReports/getRentReports', async () => {
    try {
        const { data } = await api.get(`/credit-building/getRentReport`);
        return data;
    } catch (error) {}
});
