/* eslint-disable no-unused-vars */
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getNotifications, markAsRead } from '../../../../store/features/communications/notificationsActions';
import { useNavigate } from 'react-router-dom';

// assets
import User1 from 'assets/images/users/user-round.svg';
import { createMarkup } from 'utils/createMarkUp';
import { gridSpacing } from 'store/constant';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userTokenPermissions } = useSelector((store) => store.auth);
    const { notifications } = useSelector((store) => store.notifications);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.warning.dark
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.success.dark
    };

    useEffect(() => {
        dispatch(getNotifications({}));
    }, [dispatch]);

    //get time since the message was sent
    function getTimeDifference(sqlDatetime) {
        const datetime = new Date(sqlDatetime);
        const timezoneOffsetMs = datetime.getTimezoneOffset() * 60000; // get timezone offset in ms
        const currentTime = new Date();
        let diffInMs = currentTime - datetime - timezoneOffsetMs; // adjust for timezone offset

        if (diffInMs < 0) {
            diffInMs = -diffInMs; // take absolute value if negative
        }

        let diffInMinutes = Math.floor(diffInMs / 60000);
        diffInMinutes > 1440
            ? (diffInMinutes = Math.floor(diffInMinutes / 1440) + ' Days')
            : diffInMinutes > 60
            ? (diffInMinutes = Math.floor(diffInMinutes / 60) + 'Hrs')
            : (diffInMinutes = diffInMinutes + ' Mins');
        return diffInMinutes;
    }

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.length > 0
                ? notifications
                      .filter((notification) => notification.read === 'UNREAD')
                      .map((notification) => (
                          <div key={notification.id}>
                              <ListItemWrapper
                                  onClick={() => {
                                      navigate(`/communications`);
                                  }}
                              >
                                  <ListItem alignItems="center">
                                      <ListItemAvatar>
                                          <Avatar alt="John Doe" />
                                      </ListItemAvatar>
                                      <ListItemText primary={notification?.notification?.creator?.full_names} />
                                      <ListItemSecondaryAction>
                                          <Grid container justifyContent="flex-end">
                                              <Grid item xs={12}>
                                                  <Typography variant="caption" display="block" gutterBottom>
                                                      {getTimeDifference(notification?.notification?.created_at)} Ago
                                                  </Typography>
                                              </Grid>
                                          </Grid>
                                      </ListItemSecondaryAction>
                                  </ListItem>
                                  <Grid container direction="column" className="list-container">
                                      <Grid item xs={12} sx={{ pb: 2 }}>
                                          <Typography
                                              variant="subtitle2"
                                              dangerouslySetInnerHTML={createMarkup(notification?.notification?.notification)}
                                          ></Typography>{' '}
                                      </Grid>
                                      <Grid item xs={12}>
                                          <Chip
                                              label="Mark as Read"
                                              sx={chipWarningSX}
                                              onClick={() => {
                                                  dispatch(markAsRead({ id: notification.id }));
                                              }}
                                          />
                                      </Grid>
                                  </Grid>
                              </ListItemWrapper>
                              <Divider />
                          </div>
                      ))
                : ' '}
        </List>
    );
};

export default NotificationList;
