// NavigationScroll.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// App.js
/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

const NavigationScroll = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        // Scroll to top on route change
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        // Ensure passive event listeners for performance
        const addPassiveEventListeners = () => {
            const supportsPassive = (() => {
                let passiveSupported = false;
                try {
                    const options = Object.defineProperty({}, 'passive', {
                        get: () => {
                            passiveSupported = true;
                        }
                    });
                    window.addEventListener('test', null, options);
                } catch (err) {
                    passiveSupported = false;
                }
                return passiveSupported;
            })();

            if (supportsPassive) {
                window.addEventListener('scroll', () => {}, { passive: true });
                window.addEventListener('touchstart', () => {}, { passive: true });
            }
        };

        addPassiveEventListeners();
    }, [pathname]);

    return children;
};

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
