import { createSlice } from '@reduxjs/toolkit';
import { getRentReports, getRentReportsNotPaginated } from './rentReportAction';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    RentReportsLoading: false,
    RentReportSuccess: false,
    RentReportsMsg: '',
    Reports: []
};

const RentReport = createSlice({
    name: 'RentReport',
    initialState,
    reducers: {
        clearRentReportMessages: (state) => {
            state.error = false;
            state.RentReportsMsg = '';
            state.RentReportSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRentReports.pending, (state) => {
            state.RentReportsLoading = true;
        });
        builder.addCase(getRentReports.fulfilled, (state, action) => {
            state.RentReportsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Reports = action.payload?.data;
            state.error = action.payload?.error;
            state.RentReportsMsg = action.payload?.msg;
            state.RentReportSuccess = !action.payload?.error;
        });
        builder.addCase(getRentReportsNotPaginated.fulfilled, (state, action) => {
            state.RentReportsLoading = false;
            state.Reports = action.payload?.data;
            state.error = action.payload?.error;
            state.RentReportsMsg = action.payload?.msg;
            state.RentReportSuccess = !action.payload?.error;
        });
    }
});

export const { clearRentReportMessages } = RentReport.actions;

export default RentReport.reducer;
